// const BASE_URL = 'http://medicalwaste.jiangxuanfuli.com'


// const BASE_URL = 'http://yifei-php-wxapplet.tjjtln.com'

// const BASE_URL = 'https://mall.jiangxuanapp.com'

// const BASE_URL = 'http://adminapi.jiangxuanapp.com'


const BASE_URL = 'http://yifei-php-adminapi.tjjtln.com' //正式 http://adminweb.jiangxuanfuli.com/

// const BASE_URL = 'http://adminapi.jiangxuanfuli.com' //测试







export default {
    BASE_URL
}