import Vue from 'vue'
import Vuex from 'vuex'


import item from '@/store/item.js'



Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        title: process.env.VUE_APP_TITLE,
        menuShow: true,
    },
    mutations: {
        menuShowChange(state, bool) {
            if (bool !== undefined) {
                state.menuShow = bool
            } else {
                state.menuShow = !state.menuShow
            }
        },

        setValue(state, val) {
            state.title = val
        }


    },
    actions: {},




    modules: {

        m_item: item,

    }



})