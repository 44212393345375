<template>
    <div v-loading="loading" class="table_index" :style="`height:calc(100vh - 141px - ${searchHeight}px)`"
        element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading">

        <!-- <div> -->

        <!-- :data="data.list" -->
        <!-- :data="data.list.slice((data.page-1)*data.size,data.page*data.size)" -->
        <el-table class="el-tableII" v-if="!loading"
            :data="data.list.slice((data.page-1)*data.size,data.page*data.size)" :span-method="objectSpanMethod" border
            stripe height="100%" :max-height="`calc(100vh - 183px - ${searchHeight}px)`" @selection-change="select"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" border
            :default-expand-all="data.openFlag" ref="multipleTable">
            <!-- :indent='data.indents' -->

            <el-table-column show-overflow-tooltip   type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip   label="序号" type="index" :index="indexMethod" align="center" fixed="left" />

            <slot />
        </el-table>

        <!-- </div> -->

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @change="loadData()" @size-change="getList" />

        <!-- @current-change="getList()" -->

    </div>
</template>

<script>
    export default {
        name: 'FanfuTable',
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {

            value: {
                type: Array,
                default () {
                    return []
                }
            },
            data: {
                type: Object,
                required: true
            },
            getList: {
                type: Function,
                required: true
            },
            searchHeight: {
                type: Number,
                default: 0
            },
            loading: {
                type: Boolean,
                default: false
            },

        },

        created() {
            // console.log("++++++++++++++" + this.data.size)
        },


        methods: {
            indexMethod(index) {
                return index + 1 + this.data.size * (this.data.page - 1)
            },
            select(rows) {
                this.$emit('change', rows)
            },
            toggleRowExpansion(val) {
                this.data.list.forEach(item => {
                    this.$refs.multipleTable.toggleRowExpansion(item, val);
                })
            },


            // getList() {
            //   // console.log("———————————————————— " + this.data.page);
            //   return this.data.getList;
            //   // :data="data.list"
            //   // :data="data.list.slice((data.page-1)*data.size,data.page*data.size)"
            //   // return this.data.list;
            //   // .slice((this.data.page - 1) * this.data.size, this.data.page * this.data.size);
            // },


            objectSpanMethod({
                row,
                column,
                rowIndex,
                columnIndex
            }) {

                if (this.data.objectS) {
                    // let val0 = this.data.objectS[0];
                    // let val1 = this.data.objectS[1];
                    // let val2 = this.data.objectS[2];
                    // let val3 = this.data.objectS[3];
                    // console.log(this.data.objectS)

                    for (let v = 0; v < this.data.objectS.length; v++) {

                        var array = this.data.objectS[v];

                        let val0 = array[0];
                        let val1 = array[1];
                        let val2 = array[2];
                        let val3 = array[3];

                        if (columnIndex === val0) {

                            if (rowIndex === val1) {
                                return {
                                    colspan: val2,
                                    rowspan: val3
                                };
                            }

                            for (let z = 0; z < val3; z++) {
                                if (rowIndex === val1 + z) {
                                    return {
                                        colspan: 0,
                                        rowspan: 0
                                    };
                                }
                            }

                        }

                    }

                }

            }

        }
    }
</script>
<style lang="scss" scoped>
    //   .table_index {
    //     position: relative;
    //   }
    //
</style>
<style>
    .table_index {
        position: relative;
    }

    .el-tableII {
        /* 		border: 1px red dotted !important;
		box-sizing: border-box !important; */
        /*    height: calc(100% - 100px) !important; */
        /* height: calc((100vh - 183px) - 100px); */
        /* height: auto; */
    }

    .el-table th.el-table__cell {
        background-color: #f5f5f5 !important;
    }
</style>