import Vue from 'vue'
import App from './App.vue'

import router from './router'

import store from './store'
import elementUi from 'element-ui'
import '@/assets/index.scss'
import 'font-awesome/css/font-awesome.css'
import fanfuUi from '@/fanfu-ui'
import * as utils from '@/utils'
import http from '@/utils/axios'


import '@/assets/style.css';
import 'jquery'




Vue.config.productionTip = false
Vue.prototype.$utils = utils
Vue.prototype.$http = http


import _ from 'lodash'
Vue.prototype._ = _ //全部导入的挂载方式


import mixin from '@/utils/mixin.js'; //引用异步化封装方法
Vue.mixin(mixin);





import global_ from '@/utils/global.js'
Vue.prototype.GLOBAL = global_;



Vue.use(elementUi, {
    size: 'mini'
})
Vue.use(fanfuUi)
new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')