import {
    mapState,
    mapMutations
} from 'vuex'



import GLOBAL from '@/utils/global.js'
import {
    type
} from 'jquery'


import axios from 'axios'


// module.exports 
export default {

    data() {
        return {
            permission: []
        }
    },

    created() {

    },

    computed: {
        // ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
        //     'RoleList', 'Depcate', 'ItemType',
        // ]),

        // ...mapState('m_item', ['permissionArray']),

        UserVersionId() {
            return JSON.parse(localStorage.getItem("user")).version_id
        },
        UserInfoData() {
            return JSON.parse(localStorage.getItem("user"))
        }



    },

    watch: {

    },

    updated() {

        // this.permission = JSON.parse(localStorage.getItem('permission')) ? JSON.parse(localStorage.getItem(
        //     'permission')) : []

        //     // this.getPurviewList()

    },


    methods: {
        ...mapMutations('m_item', ['setLevelList', 'setPositionList', 'setDepList', 'setDepTree', 'setHealthList',
            'setHealthListTree', 'setProvince',
            'setCity', 'setDistrict', 'setRoleList', 'setDepcate', 'setItemType',
        ]),
        // 'setpermissionArray'

        TimeToTimestamp(data) {
            return new Date(data).getTime() / 1000
        },

        timestampToTime(timestamp, type = 1) {
            // if (timestamp && timestamp.length == 0) {
            //     return ""
            // }

            if (timestamp == 0 || timestamp == null) {
                return ""
            }

            console.log(typeof(timestamp) == "number");


            if (typeof(timestamp) != "number") {
                return timestamp;
            }




            var date = new Date(timestamp * 1000); //时间戳为10位需*1000，13位的话不需要
            var Y = date.getFullYear() + "-";
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate();
            var H = date.getHours();
            var MIN = date.getMinutes();
            var S = date.getSeconds();

            if (type == 1) {
                return Y + M + this.addO(D);
            } else {
                return Y + M + this.addO(D) + " " + this.addO(H) + ":" + this.addO(MIN) + ":" + this.addO(S);
            }
        },
        addO(val) {
            var a = "0" + String(val)
            return a.slice(-2)
        },


        // 日期相隔天数
        DaysBetweenDates(date1, date2) {
            // // 日期相隔天数
            // var a = new Date("2023-7-26").getTime()
            // var b = new Date("2023-1-16").getTime()
            // var iDays = parseInt(Math.abs(a - b) / 1000 / 60 / 60 / 24)
            // console.log("日期相隔天数", iDays);

            var a = new Date(date1).getTime()
            var b = new Date(date2).getTime()
            var iDays = parseInt(Math.abs(a - b) / 1000 / 60 / 60 / 24)
            // console.log("日期相隔天数", iDays);
            return iDays

        },






        getProvinceCityDistrict(val = '0', type = '1') {

            this.$http.get('/api/city/getChilds', {
                pid: val,
            }).then((res) => {
                if (res.code == 200) {
                    switch (type) {
                        case 2:
                            console.log("市")
                            // this.setCity(res.data)

                            var a = res.data
                            a.unshift({
                                //id: null,//代表无数据
                                id: 0,
                                name: "无"
                            })
                            this.setCity(a)

                            console.log("~~~~~~~~~~~~~~>>>>>>>>>>>>>>>>>>>>>", this.City)

                            this.setDistrict([])

                            break;

                        case 3:
                            console.log("县")
                            // this.setDistrict(res.data)

                            var a = res.data
                            a.unshift({
                                //id: null,//代表无数据
                                id: 0,
                                name: "无"
                            })
                            this.setDistrict(a)

                            break;

                        default:
                            console.log("省")
                            // this.setProvince(res.data)

                            var a = res.data
                            a.unshift({
                                //id: null,//代表无数据
                                id: 0,
                                name: "无"
                            })
                            this.setProvince(a)

                            this.setCity([])
                            this.setDistrict([])

                            break;
                    }
                }
            }).catch((err) => {
                console.log(err)
            });
        },


        // 科室
        getlevel() {
            this.$http.get('/api/level', {
                page_size: 1000
            }).then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);

                    // this.setLevelList(res.data.data)

                    var a = res.data.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setLevelList(a)

                }
            }).catch((err) => {
                console.log(err)
            })
        },


        // 岗位
        getposition() {
            this.$http.get('/api/position').then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);

                    // this.setPositionList(res.data.data)

                    var a = res.data.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setPositionList(a)

                }
            }).catch((err) => {
                console.log(err)
            })
        },


        // 机构
        getdep() {
            this.$http.get('/api/dep', {
                page_size: 1000
            }).then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setDepList(res.data)

                    var a = res.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setDepList(a)

                }
            }).catch((err) => {
                console.log(err)
            })
        },

        // 机构树
        getdeptree() {
            this.$http.get('/api/dep/tree').then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setHealthList(res.data)


                    var a = res.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setDepTree(a)


                }
            }).catch((err) => {
                console.log(err)
            })
        },




        // 卫健委
        gethealth() {
            this.$http.get('/api/health').then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setHealthList(res.data)


                    var a = res.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setHealthList(a)


                }
            }).catch((err) => {
                console.log(err)
            })
        },

        // 卫健委树
        gethealthtree() {
            this.$http.get('/api/health/tree').then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setHealthList(res.data)


                    var a = res.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setHealthListTree(a)


                }
            }).catch((err) => {
                console.log(err)
            })
        },








        // 角色
        getrole() {
            this.$http.get('/api/role', {
                page: 1,
                page_size: 1000

            }).then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setRoleList(res.data.data)

                    var a = res.data.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setRoleList(a)



                }
            }).catch((err) => {
                console.log(err)
            })
        },



        // 机构分类
        getdepcate() {
            this.$http.get('/api/depcate', {
                page: 1,
                page_size: 1000

            }).then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setDepcate(res.data.data)


                    var a = res.data.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setDepcate(a)


                }
            }).catch((err) => {
                console.log(err)
            })
        },





        // 医废类型
        getitemType() {
            this.$http.get('/api/item', {
                page: 1,
                page_size: 1000

            }).then((res) => {
                if (res.code == 200) {
                    // console.log(res.data);
                    // this.setItemType(res.data.data)


                    var a = res.data.data
                    a.unshift({
                        //id: null,//代表无数据
                        id: 0,
                        name: "无"
                    })
                    this.setItemType(a)

                }
            }).catch((err) => {
                console.log(err)
            })
        },





        // getMenu() {

        //     this.$http.post('/api/login/index', {
        //         username: this.form.account,
        //         password: this.form.password
        //     }).then((res) => {


        //         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);


        //         // console.log(res)

        //         // if (res.code != 200) { //请求错误
        //         //     this.$message.error(res.msg);

        //         // }
        //         // // 登录成功
        //         // localStorage.setItem("token", res.data.token) //保存数据
        //         // this.$router.push('/home') //跳转界面

        //     }).catch((err) => {
        //         console.log(err)
        //     });


        // },



        // getPurview(val1, val2, val3) {

        //     var routes = JSON.parse(localStorage.getItem('user')).menu

        //     var purview1 = routes.filter(x => {
        //         return x.name == val1
        //     })[0]
        //     if (purview1 ? false : true) {
        //         return false
        //     }

        //     if (val3 == undefined) {
        //         var purview4 = purview1.children.filter(x => {
        //             return x.name == val2
        //         })
        //         return purview4.length ? true : false
        //     }

        //     var purview2 = purview1.children.filter(x => {
        //         return x.name == val2
        //     })[0]
        //     if (purview2 ? false : true) {
        //         return false
        //     }

        //     var purview3 = purview2.children.filter(x => {
        //         return x.name == val3
        //     })
        //     return purview3.length ? true : false

        // },







        getPurviewNew(val) {

            // console.log("++++++++++++++>>>>>>>>>>>>", this.permissionArray);

            var a = JSON.parse(localStorage.getItem('permission')).length > 0 ? JSON.parse(localStorage.getItem(
                'permission')) : []

            return a.includes(val)

            // if (this.permission && this.permission.length > 0) {
            // } else {
            //     return false
            // }

            // return true
        },

        getPurviewList: _.debounce(function() {
            console.log("！！！！！！！！！！！！！！！！！！！updated");

            var routes = JSON.parse(localStorage.getItem('user')).menu
            this.formatTree(routes, 0)

        }, 1000),

        formatTree(tree, val) {
            if (!tree || !tree.length) return;
            for (const node of tree) {
                node.level = val
                if (node.children && node.children.length) {
                    this.formatTree(node.children, val + 1)
                    // } else {

                }

                if (node.permission && node.permission.length > 0) {

                    this.permission.push(node.permission)
                    // console.log(this.permission)
                    this.savePermissionArray()

                }
            }
            return tree
        },

        savePermissionArray: _.debounce(function() {

            localStorage.setItem('permission', JSON.stringify(this.permission))

        }, 500),





        // autoTableCell({
        //     row,
        //     column
        // }) {
        //     console.log("====>>>>", column.label);
        //     if (column.label == "操作") {
        //         return `
        //          width:100px !important; 
        //         border: 1px purple solid;
        //         box-sizing: border-box;
        //         `
        //     }
        // }


        autoTableWidth(array, width) {

            var a = JSON.parse(localStorage.getItem('permission')).length > 0 ? JSON.parse(localStorage.getItem(
                'permission')) : []

            var b = 0

            var c = width //(width - 10) / array.length

            array.map(x => {
                if (a.includes(x)) {
                    b++
                }
            })

            return b == 0 ? c : b * c

        },

        // debounce(function() {

        // }, 1000)






        removePropertyOfNull(obj) {
            Object.keys(obj).forEach(item => {
                if (!obj[item]) {
                    delete obj[item]
                }

                if (obj[item] == null || obj[item] == undefined) {
                    delete obj[item]
                }


            })
            return obj;
        },












        // 导出明细
        DetailExport: _.debounce(
            function(wasteInfo) {

                wasteInfo = {
                    ...wasteInfo
                }




                console.log("-------------------》", wasteInfo);

                if (!wasteInfo.create_time && !wasteInfo.sealing_time && !wasteInfo.out_storage_time && !wasteInfo
                    .storage_time && !wasteInfo.collector_time) {

                    //   type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收    

                    var urlStr = ""
                    var urlStrurlStr = ""

                    urlStrurlStr = wasteInfo
                    urlStrurlStr.admin_id = JSON.parse(localStorage.getItem('user')).id

                    // urlStrurlStr.dep_id = wasteInfo.dep_id ? wasteInfo.dep_id : JSON.parse(localStorage.getItem(
                    //         'user'))
                    //     .dept_id


                    urlStrurlStr.dep_id = wasteInfo.dep_id ? wasteInfo.dep_id.slice(-1)[0] : JSON.parse(localStorage
                        .getItem('user')).dept_id






                    console.log("-------------------》", urlStrurlStr);

                    if (wasteInfo.type == 1) {
                        // /number/excelSealing
                        console.log("-------------------》封箱");
                        urlStr = "/api/number/excelSealing"

                    }
                    if (wasteInfo.type == 2) {
                        // /number/excelInStorage
                        console.log("-------------------》入库");
                        urlStr = "/api/number/excelInStorage"

                    }
                    if (wasteInfo.type == 3) {
                        // /number/excelOutStorage
                        console.log("-------------------》出库");
                        urlStr = "/api/number/excelOutStorage"

                    }
                    if (wasteInfo.type == 4) {
                        // /number/excel
                        console.log("-------------------》数据对比");

                        delete wasteInfo.sealing_time
                        delete wasteInfo.out_storage_time
                        delete wasteInfo.storage_time

                        if (this.UserVersionId == 1) {
                            delete wasteInfo.collector_time
                        }
                        if (this.UserVersionId == 2) {
                            delete wasteInfo.create_time
                        }

                        urlStr = "/api/number/excel"

                    }
                    if (wasteInfo.type == 5) {
                        console.log("-------------------》可回收");

                        delete wasteInfo.sealing_time
                        delete wasteInfo.out_storage_time
                        delete wasteInfo.storage_time

                        if (this.UserVersionId == 1) {
                            delete wasteInfo.collector_time
                        }
                        if (this.UserVersionId == 2) {
                            delete wasteInfo.create_time
                        }

                        urlStr = "/api/number/excel"
                    }

                    this.$http.get(urlStr, {
                        ...urlStrurlStr,
                        type: 'check'

                    }).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code == 200) {




                            //   type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收    
                            console.log(wasteInfo);


                            var urlStr = ""
                            var urlStrurlStr = ""

                            urlStrurlStr = wasteInfo
                            urlStrurlStr.admin_id = JSON.parse(localStorage.getItem('user')).id
                            urlStrurlStr.dep_id = wasteInfo.dep_id ? wasteInfo.dep_id : JSON.parse(
                                    localStorage.getItem('user'))
                                .dept_id

                            console.log("-------------------》", urlStrurlStr);


                            if (wasteInfo.type == 1) {
                                // /number/excelSealing
                                console.log("-------------------》封箱");
                                urlStr = "/number/excelSealing"

                            }
                            if (wasteInfo.type == 2) {
                                // /number/excelInStorage
                                console.log("-------------------》入库");
                                urlStr = "/number/excelInStorage"

                            }
                            if (wasteInfo.type == 3) {
                                // /number/excelOutStorage
                                console.log("-------------------》出库");
                                urlStr = "/number/excelOutStorage"

                            }
                            if (wasteInfo.type == 4) {
                                // /number/excel
                                console.log("-------------------》数据对比");

                                delete wasteInfo.sealing_time
                                delete wasteInfo.out_storage_time
                                delete wasteInfo.storage_time

                                if (this.UserVersionId == 1) {
                                    delete wasteInfo.collector_time
                                }
                                if (this.UserVersionId == 2) {
                                    delete wasteInfo.create_time
                                }

                                urlStr = "/number/excel"

                            }
                            if (wasteInfo.type == 5) {
                                console.log("-------------------》可回收");

                                delete wasteInfo.sealing_time
                                delete wasteInfo.out_storage_time
                                delete wasteInfo.storage_time

                                if (this.UserVersionId == 1) {
                                    delete wasteInfo.collector_time
                                }
                                if (this.UserVersionId == 2) {
                                    delete wasteInfo.create_time
                                }

                                urlStr = "/number/excel"

                            }

                            urlStrurlStr = this.removePropertyOfNull(urlStrurlStr)

                            var c = GLOBAL.BASE_URL + urlStr + '?' +
                                Object.entries(urlStrurlStr).map(([k, v]) =>
                                    `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                                .join(
                                    '&');

                            console.log(c);

                            let v = document.createElement('a') //创建一个a标签元素
                            v.href = c //设置下载地址
                            v.setAttribute('target', "_self");

                            v.click() //触发点击,下载






                        }
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });


                    return
                }



                if (this.ExportTime(wasteInfo) != true) {
                    this.$message.error("最多导出3个月的数据！")
                    return
                }






                //   type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收    
                console.log(wasteInfo);


                var urlStr = ""
                var urlStrurlStr = ""

                urlStrurlStr = wasteInfo
                urlStrurlStr.admin_id = JSON.parse(localStorage.getItem('user')).id
                urlStrurlStr.dep_id = wasteInfo.dep_id ? wasteInfo.dep_id : JSON.parse(localStorage.getItem('user'))
                    .dept_id

                console.log("-------------------》", urlStrurlStr);


                if (wasteInfo.type == 1) {
                    // /number/excelSealing
                    console.log("-------------------》封箱");
                    urlStr = "/number/excelSealing"

                }
                if (wasteInfo.type == 2) {
                    // /number/excelInStorage
                    console.log("-------------------》入库");
                    urlStr = "/number/excelInStorage"

                }
                if (wasteInfo.type == 3) {
                    // /number/excelOutStorage
                    console.log("-------------------》出库");
                    urlStr = "/number/excelOutStorage"

                }
                if (wasteInfo.type == 4) {
                    // /number/excel
                    console.log("-------------------》数据对比");

                    delete wasteInfo.sealing_time
                    delete wasteInfo.out_storage_time
                    delete wasteInfo.storage_time

                    if (this.UserVersionId == 1) {
                        delete wasteInfo.collector_time
                    }
                    if (this.UserVersionId == 2) {
                        delete wasteInfo.create_time
                    }

                    urlStr = "/number/excel"

                }
                if (wasteInfo.type == 5) {
                    console.log("-------------------》可回收");

                    delete wasteInfo.sealing_time
                    delete wasteInfo.out_storage_time
                    delete wasteInfo.storage_time

                    if (this.UserVersionId == 1) {
                        delete wasteInfo.collector_time
                    }
                    if (this.UserVersionId == 2) {
                        delete wasteInfo.create_time
                    }

                    urlStr = "/number/excel"

                }



                var c = GLOBAL.BASE_URL + urlStr + '?' +
                    Object.entries(urlStrurlStr).map(([k, v]) =>
                        `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                    .join(
                        '&');

                console.log(c);

                let v = document.createElement('a') //创建一个a标签元素
                v.href = c //设置下载地址
                v.setAttribute('target', "_self");

                v.click() //触发点击,下载





            }, 1000),



        // 导出统计
        SummaryExport: _.debounce(
            function(wasteInfo) {

                wasteInfo = {
                    ...wasteInfo
                }



                // if (this.ExportTime(wasteInfo) == false) {
                //     this.$message.error("间隔时间不能大于3个月")
                //     return
                // }


                var urlStr = "/number/execlTotal"
                var urlStrurlStr = {}

                urlStrurlStr.type = wasteInfo.type
                urlStrurlStr.number = wasteInfo.number
                urlStrurlStr.item_id = wasteInfo.item_id
                // urlStrurlStr.collector_id = wasteInfo.collector_id
                // urlStrurlStr.create_user = wasteInfo.create_user
                // urlStrurlStr.create_time = wasteInfo.create_time
                urlStrurlStr.dep_id = wasteInfo.dep_id
                urlStrurlStr.rkstatus = wasteInfo.rkstatus
                urlStrurlStr.level_id = wasteInfo.level_id
                urlStrurlStr.is_in_storage = wasteInfo.is_in_storage

                // urlStrurlStr.admin_id = wasteInfo.admin_id
                // urlStrurlStr.admin_id = JSON.parse(localStorage.getItem('user')).id
                // urlStrurlStr.dep_id = JSON.parse(localStorage.getItem('user'))
                //     .dept_id

                urlStrurlStr.dep_id = wasteInfo.dep_id ? wasteInfo.dep_id.slice(-1)[0] : JSON.parse(localStorage
                    .getItem('user')).dept_id





                // wasteInfo.dep_id ? wasteInfo.dep_id :


                if (wasteInfo.type == 1) {
                    console.log("-------------------》封箱");
                    if (this.UserVersionId == 1) {
                        urlStrurlStr.create_time = wasteInfo.create_time
                    }
                    if (this.UserVersionId == 2) {
                        urlStrurlStr.collector_time = wasteInfo.collector_time
                    }
                }
                if (wasteInfo.type == 2) {
                    console.log("-------------------》入库");
                    urlStrurlStr.is_in_storage = "1,2"
                    urlStrurlStr.storage_time = wasteInfo.storage_time
                }
                if (wasteInfo.type == 3) {
                    console.log("-------------------》出库");
                    urlStrurlStr.out_storage_time = wasteInfo.out_storage_time
                }
                if (wasteInfo.type == 4) {
                    console.log("-------------------》数据对比");

                    if (this.UserVersionId == 1) {
                        urlStrurlStr.create_time = wasteInfo.create_time
                    }
                    if (this.UserVersionId == 2) {
                        urlStrurlStr.collector_time = wasteInfo.collector_time
                    }
                }
                if (wasteInfo.type == 5) {
                    console.log("-------------------》可回收");
                    if (this.UserVersionId == 1) {
                        urlStrurlStr.create_time = wasteInfo.create_time
                    }
                    if (this.UserVersionId == 2) {
                        urlStrurlStr.collector_time = wasteInfo.collector_time
                    }
                }

                if (wasteInfo.type == 6) {
                    console.log("-------------------》回收站");

                    urlStrurlStr.mark = 0

                    if (this.UserVersionId == 1) {
                        urlStrurlStr.create_time = wasteInfo.create_time
                    }
                    if (this.UserVersionId == 2) {
                        urlStrurlStr.collector_time = wasteInfo.collector_time
                    }
                }


                urlStrurlStr = this.removePropertyOfNull(urlStrurlStr)


                var c = GLOBAL.BASE_URL + urlStr + '?' +
                    Object.entries(urlStrurlStr).map(([k, v]) =>
                        `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                    .join(
                        '&');

                console.log(c);

                let v = document.createElement('a') //创建一个a标签元素
                v.href = c //设置下载地址
                v.setAttribute('target', "_self");
                // v.setAttribute('target', "_black");

                v.click() //触发点击,下载



                // //   type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收    
                // console.log(wasteInfo);

                // if (wasteInfo.type == 1) {
                //     // /number/excelSealing
                //     console.log("-------------------》封箱");
                // }
                // if (wasteInfo.type == 2) {
                //     // /number/excelInStorage
                //     console.log("-------------------》入库");
                // }
                // if (wasteInfo.type == 3) {
                //     // /number/excelOutStorage
                //     console.log("-------------------》出库");
                // }
                // if (wasteInfo.type == 4) {
                //     // /number/excel
                //     console.log("-------------------》数据对比");
                // }
                // if (wasteInfo.type == 5) {
                //     console.log("-------------------》可回收");

                // }





                // var b = {
                //     sealing_time: wasteInfo.sealing_time.toString(),
                //     dep_id: wasteInfo.dep_id ? wasteInfo.dep_id : JSON.parse(localStorage.getItem('user')).dept_id
                // }

                // // var c = GLOBAL.BASE_URL + "/number/execlTotal/" + JSON.stringify(b)

                // var c = GLOBAL.BASE_URL + "/number/execlTotal?" + "sealing_time=" + b.sealing_time.toString() +
                //     "&dep_id=" +
                //     b.dep_id

                // console.log(c);

                // let a = document.createElement('a') //创建一个a标签元素
                // a.href = c //设置下载地址
                // a.setAttribute('target', "_self");
                // a.click() //触发点击,下载


            }, 1000),


        ExportTime(wasteInfo) {

            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>", wasteInfo);


            if (wasteInfo.create_time && wasteInfo.create_time.length > 1) {

                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>1");

                if (this.apartDay(wasteInfo.create_time) > 92) {
                    return false
                }
            }

            if (wasteInfo.sealing_time && wasteInfo.sealing_time.length > 1) {

                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>2");

                if (this.apartDay(wasteInfo.sealing_time) > 92) {
                    return false
                }
            }

            if (wasteInfo.out_storage_time && wasteInfo.out_storage_time.length > 1) {

                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>3");

                if (this.apartDay(wasteInfo.out_storage_time) > 92) {
                    return false
                }
            }

            if (wasteInfo.storage_time && wasteInfo.storage_time.length > 1) {

                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>4");

                if (this.apartDay(wasteInfo.storage_time) > 92) {
                    return false
                }
            }

            if (wasteInfo.collector_time && wasteInfo.collector_time.length > 1) {

                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>5");

                if (this.apartDay(wasteInfo.collector_time) > 92) {
                    return false
                }
            }








            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>", this.apartDay(wasteInfo.collector_time), this.apartDay(
            //     wasteInfo
            //     .collector_time) > 92);






            // 如果都是空,则赋值

            if (!wasteInfo.create_time && !wasteInfo.sealing_time && !wasteInfo.out_storage_time && !wasteInfo
                .storage_time && !wasteInfo.collector_time) {
                console.log("如果都是空,则赋值如果都是空,则赋值如果都是空,则赋值");

                return

                // // <!-- type    导出类型：1封箱、2入库、3出库、4数据对比、5可回收 -->

                // var b = this.Today() // 今天
                // var a = this.ThreeMonthsAgo() // 三个月之前



                // this.$message.warning(`当前为${a}至${b},最多导出近3个月的数据！`)


                // console.log(a, b);

                // wasteInfo.create_time = [a, b].toString()
                // wasteInfo.sealing_time = [a, b].toString()
                // wasteInfo.out_storage_time = [a, b].toString()
                // wasteInfo.storage_time = [a, b].toString()



            }

            return true
        },


        apartDay(array) {
            console.log("!!!!!!!!!!!", array, type(array));
            if (type(array) != "array") {
                array = array.split(',')
            }

            var n = this.DateDiff(array[0], array[1]);
            console.log("相差天数：", n);
            return n
        },

        DateDiff(sDate1, sDate2) {
            var date1 = new Date(sDate1)
            var date2 = new Date(sDate2)
            var diff = Math.abs(date2.getTime() - date1.getTime())
            var diffDays = Math.round(diff / (1000 * 60 * 60 * 24))
            return diffDays
        },

        Today() {
            var date = new Date(); //时间戳为10位需*1000，13位的话不需要
            var Y = date.getFullYear() + "-";
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate();
            var H = date.getHours();
            var MIN = date.getMinutes();
            var S = date.getSeconds();
            return Y + M + D;
        },

        ThreeMonthsAgo() {
            const date = new Date();
            const currentMonth = date.getMonth();
            date.setMonth(currentMonth - 3);

            var Y = date.getFullYear() + "-";
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate();
            var H = date.getHours();
            var MIN = date.getMinutes();
            var S = date.getSeconds();

            return Y + M + D;
        }

    }

}