<template>
  <el-scrollbar class="fanfu-scrollbar" style="height: 100%">
    <slot />
  </el-scrollbar>
</template>

<script>
  export default {
    name: 'FanfuScrollbar'
  }
</script>

<style lang="scss">
  @import '@/assets/var.scss';

  .fanfu-scrollbar {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-scrollbar__thumb {
      background: $--color-primary;
      opacity: 0.3;

      &:hover {
        background: $--color-primary;
        opacity: 1;
      }
    }
  }
</style>
