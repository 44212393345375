import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = '' // 正式
// axios.defaults.baseURL = 'http://centerapi.jiangxuanfuli.com' // 测试

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 允许跨域携带cookie信息
axios.defaults.withCredentials = true
// 设置超时
axios.defaults.timeout = 30000

// axios.interceptors.request.use(
//   (config) => {
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

axios.interceptors.request.use(
    (config) => {

        let token = localStorage.getItem("token");
        if (token) {
            // config.headers.Authorization = 'bearer ' + token
            config.headers.token = token
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)




axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {

            // 判断是否登录
            if (response.data.code == 50001 || response.data.code == 50005 || response.data.code == 50004) {
                localStorage.clear()
                window.location.href = '#/';
                // this.$router.push('/')
            }

            return Promise.resolve(response)

        } else {

            return Promise.reject(response)
        }
    },
    (error) => {
        console.log(JSON.stringify(error))
    }
)
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    data: data
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    params: data
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },


    put(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'put',
                    url,
                    data: data
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    del(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'delete',
                    url,
                    data: data
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },




    upload(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    data,
                    // headers: { "Content-Type": "multipart/form-data" },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },



    patch(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'patch',
                    url,
                    data,
                    // headers: { "Content-Type": "multipart/form-data" },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',

                    },
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },





}