const files = require.context('./components/', true, /Index.vue/)
const components = []
for (const path of files.keys()) {
  components.push(files(path).default)
}
const install = function (Vue) {
  if (install.installed) return
  install.installed = true
  components.map((component) => Vue.component(component.name, component))
}
export default { install }
