<template>
  <el-image
    v-if="url"
    :src="`http://yifei-php-admin.tjjtln.com${url}`"
    fit="fill"
    :preview-src-list="[`http://yifei-php-admin.tjjtln.com${url}`]"
  />
</template>

<script>
export default {
  name: 'FanfuImage',
  props: {
    url: {
      type: String,
      default: ''
    }
  }
}
</script>

<style></style>
