import Vue from 'vue'
import VueRouter from 'vue-router'




Vue.use(VueRouter)
let _import
if (process.env.NODE_ENV === 'development') {
    _import = (path) => require('@/views' + path + '.vue').default
} else {
    _import = (path) => () => import('@/views' + path + '.vue')
}

let loading = false

const router = new VueRouter({
    routes: [{
            path: '/',
            name: '登录',
            component: () => import('@/views/layout/Login.vue')
        },
        {
            path: '/layout',
            name: '框架',
            component: () => import('@/views/layout/Layout.vue'),
            children: []
        },

        {
            path: '/view',
            component: () => import('@/views/pages/Home.vue'),

        },

        {
            path: '/map',
            component: () => import('@/views/pages/zShowView/Home'),
        },

        {
            path: '*',
            component: () => import('@/views/layout/404.vue')
        },

    ]
})







// import routes from './routes.json'

// router.beforeEach((to, from, next) => {
//     if (!loading) {
//         addRoutes(routes)
//         next({
//             ...to,
//             replace: true
//         })
//     } else {
//         next()
//     }
// })

// // export default router
// export {
//     routes
// }

// function addRoutes(menus) {
//     for (const menu of menus) {
//         if (menu.children?.length > 0) {
//             addRoutes(menu.children)
//         } else {
//             router.addRoute('框架', {
//                 path: menu.url,
//                 name: menu.title,
//                 component: _import(menu.url)
//             })
//         }
//     }
//     loading = true
// }








export const addrouterrouter = (data) => asdasd(data)

if (localStorage.getItem('user')) {
    var routes = JSON.parse(localStorage.getItem('user')).menu
    asdasd(routes)
}

function asdasd(routes1) {

    // routes1 = routes1.concat([{
    //     name: "首页",
    //     icon: "el-icon-house",
    //     url: "/pages/Home"
    // }, {
    //     name: "个人中心",
    //     icon: "el-icon-user",
    //     url: "/pages/User/User"
    // }])

    console.log("routesroutesroutesroutesroutesroutesroutesroutes", routes1)

    router.beforeEach((to, from, next) => {
        if (!loading) {
            addRoutes(routes1)
            next({
                ...to,
                replace: true
            })
        } else {
            next()
        }
    })
}

function addRoutes(menus) {

    // console.log(menus);
    // console.log(menus[0].level);

    // if (menus[0].level > 1) {
    //     return
    // }


    // console.log('~~~~~~~~~~~~>>>>>>>', menus);


    for (const menu of menus) {

        // console.log(menu);

        // console.log(menu.name, menu.children && (menu.children.filter(
        //     x => {
        //         return x.type != 4
        //     }).length > 0) ? "<<<===" : "");


        // if ((menu.children ? true : false) && menu.level == 0 && (menu.children && (menu.children.filter(
        //         x => {
        //             return x.type != 4
        //         }).length > 0) ? true : false)) {

        //     addRoutes(menu.children)

        // } else {


        //     // if (menu.url.length > 0) {

        //     //     router.addRoute('框架', {
        //     //         path: menu.url,
        //     //         name: menu.name,
        //     //         component: _import(menu.url)
        //     //     })

        //     // }


        //     // console.log('~~~~~~~~~~~~>>>>>>>', menu, menu.route);

        //     // if (menu.route.length > 0) {
        //     if (menu.route != null) {
        //         router.addRoute('框架', {
        //             path: menu.route,
        //             name: menu.name,
        //             component: _import(menu.route)
        //         })
        //     }


        //     // if (menu.children) {
        //     //     for (const item of menu.children) {
        //     //         router.addRoute(router, {
        //     //             path: item.url,
        //     //             name: item.name,
        //     //             component: _import(item.url)
        //     //         })
        //     //     }
        //     // }

        // }







        if (menu.children != null) {

            addRoutes(menu.children)

        }




        if (menu.route != null && menu.route != '') {
            // console.log('~~~~~~~~~~~~>>>>>>>', menu.route, menu.route != null);

            router.addRoute('框架', {
                path: menu.route,
                name: menu.name,
                component: _import(menu.route)
            })

        }


        // if ((menu.children ? true : false) && menu.level == 0 && (menu.children && (menu.children.filter(
        //         x => {
        //             return x.type != 4
        //         }).length > 0) ? true : false)) {

        // } else {



        //     console.log('~~~~~~~~~~~~>>>>>>>', menu, menu.route);


        //     if (menu.route != null) {
        //         router.addRoute('框架', {
        //             path: menu.route,
        //             name: menu.name,
        //             component: _import(menu.route)
        //         })
        //     }



        // }








    }

    loading = true
}


const originalReplace = VueRouter.prototype.replace

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}








// 路由守卫
router.beforeEach((to, from, next) => {

    // if (to.path === '/view') return next()
    if (to.path === '/map') return next()




    if (to.path === '/') return next()

    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/')
    next()

})



router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});




export default router