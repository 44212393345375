<template>
  <span>
    <el-input v-if="select" v-model="data" readonly>
      <template #prefix>
        <Fanfu-Icon style="line-height: 28px" :icon="data" />
      </template>
      <span slot="append">
        <el-button icon="el-icon-search" @click="show = true" />
      </span>
    </el-input>
    <!--    <i v-else :class="icon" :style="{ fontSize: `${size}px`, verticalAlign: 'middle' }"
      style="border: 0.25px aqua dotted;box-sizing: content-box;" /> -->

    <i :class="icon" :style="{ fontSize: `${size}px`, verticalAlign: 'middle' }"
      style="box-sizing: content-box;width: 16px;height: 16px;display: inline-block;" />


    <el-dialog :close-on-click-modal="false" :visible.sync="show" title="选择图标" width="calc(100% - 400px)" top="5vh" append-to-body>
      <el-tabs type="border-card">
        <el-tab-pane v-for="(iconGroup, index) in ICONS" :key="`group_${index}`" :label="iconGroup.title">
          <div class="icons">
            <i v-for="(iconObj, iconIndex) in iconGroup.icons" :key="`icon_${iconIndex}`"
              :class="`icon ${iconObj} ${iconObj === data ? 'select' : ''}`" @click="choose(iconObj)" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </span>
</template>

<script>
  import ICONS from './icon.json'
  export default {
    name: 'FanfuIcon',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      select: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ''
      },
      size: {
        type: Number,
        default: 16
      }
    },
    data() {
      return {
        ICONS,
        data: '',
        show: false
      }
    },
    watch: {
      value() {
        this.getValue()
      }
    },
    created() {
      this.getValue()
    },
    methods: {
      getValue() {
        if (this.value) {
          this.data = this.value
        } else {
          this.data = ''
        }
      },
      choose(icon) {
        this.$emit('change', icon)
        this.show = false
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/var.scss';

  .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      border: 1px solid transparent;

      &:hover {
        cursor: pointer;
        color: $--color-primary;
        font-size: 24px;
      }

      &.select {
        font-size: 24px;
        border: 1px solid $--color-primary;
      }
    }
  }
</style>
