// export function notNull (value) {
//   const type = Object.prototype.toString.call(value)
//   switch (type) {
//     case '[object String]':
//       return value !== ''
//     case '[object Boolean]':
//       return true
//     case '[object Number]':
//       return true
//     default:
//       return value
//   }
// }

import http from './axios.js'
//登录
export const loginAPI = (params) => http.post('/api/login/index', params)
//退出
export const logoutAPI = (params) => http.post('/api/login/logout', params)