<template>

    <!--  <div v-if="getChildren.length> 0" class="fanfu_submenu">

    <div class="title" :class="{ open: open }" :title="menu.title" @click="open = !open">
      <Fanfu-Icon :icon="menu.icon" />
      <span v-if="$store.state.menuShow">
        {{ menu.title }}
      </span>
      <i v-if="$store.state.menuShow" class="menu_arrow el-icon-arrow-down" />
    </div>

    <div v-if="open">
      <Fanfu-Menu-Item v-for="(child, index) in getChildren" :key="`menu_${index}`" :menu="child" />
    </div>

  </div>


  <div v-else class="fanfu_menu">

    <div class="title" :class="{ select: $route.path === menu.path }" :title="menu.title"
      @click="$router.push(menu.path)">
      <Fanfu-Icon :icon="menu.icon" />
      <span v-if="$store.state.menuShow">
        {{ menu.title }}
      </span>
    </div>

  </div>  -->


    <div :class="{'paddingpadding':$store.state.menuShow}">


        <div v-if="getChildren.length > 0" class="fanfu_submenu" @click="closeMenu()">

            <!-- @mouseover="$store.state.menuShow?'':open = true"
    @mouseout="$store.state.menuShow?'':open = false" -->

            <div class="title" :class="{ open: open }" :title="menu.title" @click="open = !open"
                style="border: 0px green solid;">

                <Fanfu-Icon :icon="menu.icon" />
                <span v-if="$store.state.menuShow">
                    {{ menu.title }}
                </span>
                <i v-if="$store.state.menuShow" class="menu_arrow el-icon-arrow-down" />
            </div>

            <div v-if="open" :class="$store.state.menuShow?'':'FloatingMenu'">
                <Fanfu-Menu-Item v-for="(child, index) in getChildren" :key="`menu_${index}`" :menu="child"
                    :index="index" />
            </div>

        </div>

        <div v-else class="fanfu_menu">

            <div class="title" :class="{ select: $route.path === menu.path }" :title="menu.title"
                style="border: 0px purple solid;" @click="jumpAndClose($event)">

                <Fanfu-Icon :icon="menu.icon" />

                <span v-if="$store.state.menuShow?true:(open===false&&menu.icon.length==0)">
                    {{ menu.title }}
                </span>

            </div>

        </div>


    </div>



</template>

<script>
    export default {
        name: 'FanfuMenuItem',
        props: {
            menu: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            CloseMenuExcept: {
                type: Function,
                default: null
            }
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            getChildren() {

                const type = Object.prototype.toString.call(this.menu.children)
                switch (type) {
                    case '[object Array]':
                        return this.menu.children
                    default:
                        return []
                }
            }
        },


        // mounted() {
        //   // 监听全局点击事件
        //   document.addEventListener("click", this.bodyCloseMenus);
        // },
        // beforeDestroy() {
        //   // 在页面注销前，将点击事件给移除
        //   document.removeEventListener("click", this.bodyCloseMenus);
        // },


        methods: {

            // bodyCloseMenus(e) {
            //   this.$parent.CloseMenuExcept();
            // },



            closeMenu() {
                this.$parent.CloseMenuExcept(this.index)
            },
            closeFun() {
                if (!this.$store.state.menuShow) {
                    this.open = false;
                }
            },
            jumpAndClose(e) {

                this.$parent.CloseMenuExcept();

                const a = window.location.href;
                const b = this.menu.path;
                const c = a.indexOf(b)
                if (c == -1) {
                    this.$router.push(this.menu.path)
                }

            }

        }
    }
</script>
<style lang="scss">
    @import '@/assets/var.scss';

    .FloatingMenu {
        position: fixed;
        left: 10px;
        // top: -50px;

        margin-left: 50px;
        margin-top: -50px;

        background-color: white;
        box-shadow: 0px 4px 10px 0px rgba(103, 103, 103, 0.6400);
        border-radius: 10px;

        width: 150px;
        padding: 10px 0px;
        // padding-bottom: 10px;

        // border: 1px red solid;

    }

    .FloatingMenu .fanfu_menu .title span+span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        text-align: center;


        // border: 1px green solid;
    }


    .fanfu_submenu {
        font-size: 14px;
        // border: 1px green solid;
        // padding: 0 10px;

        .title {
            // width: 60%;
            height: 40px;
            line-height: 40px;
            margin: 5px auto;
            border-radius: 4px;
            padding-left: 20px;
            cursor: pointer;
            position: relative;

            // margin-left: 10px;
            // border: 1px green solid !important;

            .menu_arrow {
                position: absolute;
                right: 10px;
                top: calc(50% - 7px);
                transition: transform 0.3s;
            }

            &.open {
                background: #f6f6f6;
                box-shadow: 4px 1px 8px 0px rgba(162, 162, 162, 0.56);

                .menu_arrow {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .fanfu_menu {
        font-size: 14px;

        // border: 1px red solid;
        // padding: 0 10px;

        .title {
            // width: 60%;
            height: 40px;
            line-height: 40px;
            margin: 5px auto;
            border-radius: 4px;
            padding-left: 20px;
            cursor: pointer;
            position: relative;

            // margin-left: 10px;
            // border: 1px red solid !important;

            &.select {
                background: $--color-primary;
                box-shadow: 4px 1px 8px 0px rgba(162, 162, 162, 0.56);
                color: #fff;
            }
        }
    }

    .paddingpadding {
        padding: 0 10px;
        // border: 1px red solid;

    }
</style>