export default {
    // 为当前模块开启命名空间
    namespaced: true,

    // 数据
    state: () => ({
        // level: [],
        // dep: [],

        MedicalWasteItem: [],


        LevelList: [],

        PositionList: [],

        DepList: [],
        DepTree: [],

        HealthList: [],
        HealthListTree: [],


        // 省市县
        Province: [],
        City: [],
        District: [],


        RoleList: [],

        Depcate: [],



        ItemType: [],




        // permissionArray: []




    }),

    mutations: {

        // setlevel(state, level) {
        //     state.level = level
        // },

        // setdep(state, dep) {
        //     state.dep = dep
        // },

        setMedicalWasteItem(state, MedicalWasteItem) {
            state.MedicalWasteItem = MedicalWasteItem
        },


        // 科室
        setLevelList(state, LevelList) {
            state.LevelList = LevelList
        },

        // 岗位
        setPositionList(state, PositionList) {
            state.PositionList = PositionList
        },

        // 机构
        setDepList(state, DepList) {
            state.DepList = DepList
        },

        setDepTree(state, DepTree) {
            state.DepTree = DepTree
        },


        // 卫健委
        setHealthList(state, HealthList) {
            state.HealthList = HealthList
        },
        // 卫健委树
        setHealthListTree(state, HealthListTree) {
            state.HealthListTree = HealthListTree
        },



        // 省市县
        setProvince(state, Province) {
            state.Province = Province
        },
        setCity(state, City) {
            state.City = City
        },
        setDistrict(state, District) {
            state.District = District
        },


        // 角色
        setRoleList(state, RoleList) {
            state.RoleList = RoleList
        },




        // 
        setDepcate(state, Depcate) {
            state.Depcate = Depcate
        },




        // 医废类型
        setItemType(state, ItemType) {
            state.ItemType = ItemType
        },







        // setpermissionArray(state, permissionArray) {
        //     state.permissionArray = permissionArray
        // },




    },

    // 
    getters: {},

}